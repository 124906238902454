@import 'shared';

.root {
  @include page-spacing;
  @include motion-page-init;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: px(12);
  padding-bottom: px(32);

  @include media-tablet {
    padding-top: px(12);
    padding-bottom: px(40);
  }

  .header {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: px(6);
    overflow: hidden;
    padding-bottom: px(6);

    @include media-tablet {
      flex-direction: row;
      align-items: flex-end;
      gap: px(24);
    }

    .title {
      @include typography-404;

      @include media-tablet {
        margin-bottom: px(6);
      }

      @include media-desktop {
        margin-bottom: 0;
      }
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      align-items: baseline;

      @include media-desktop {
        margin-bottom: px(-6);
      }
    }

    .paraTopWrapper {
      display: flex;
      align-items: center;
      gap: 0;

      @include media-tablet {
        gap: px(10);
      }

      .line {
        opacity: 0;

        @include media-tablet {
          display: block;
          height: 1px;
          width: px(48);
          background-color: $black;
          opacity: 1;
        }

        @include media-desktop {
          width: px(64);
        }
      }

      .paraTop {
        margin-bottom: -0.25em;
      }
    }
  }

  .image {
    @include columns-width(2, 1.5, 1);
    top: 20%;
    transform: translateY(-25%);
  }
}
